import i18n from '@/i18n';

const usefulDocumentsSections = [
  {
    label: i18n.t('usefulDocuments.policySection'),
    to: { name: 'Policy' },
  },
  {
    label: i18n.t('usefulDocuments.instructionsSection'),
    to: { name: 'Instructions' },
  },
  {
    label: i18n.t('usefulDocuments.manualsSection'),
    to: { name: 'Manuals' },
  },
  {
    label: i18n.t('usefulDocuments.glossarySection'),
    to: { name: 'Glossary' },
  },
];

export default usefulDocumentsSections;
