<template>
  <div class="documents">
    <Tabs :tabs="sections"></Tabs>
    <router-view />
  </div>
</template>

<script>
import usefulDocumentsSections from '@/config/usefulDocumentsSections';
import Tabs from '@/components/Tabs.vue';

export default {
  name: 'UsefulDocuments',
  data() {
    return {
      sections: usefulDocumentsSections,
    };
  },
  components: {
    Tabs,
  },
};
</script>
